import { createRouter, createWebHistory } from 'vue-router' 

const routes = [
  {
    path: '/',
    component: () => import('./IndexComponent.vue'),
  }, 
  {
    path: '/medicine_line',
    component: () => import('./components/MedicineLineComponent.vue')
  },
  {
    path: '/profi_line',
    component: () => import('./components/ProfiLineComponent.vue')  
  },
  {
    path: '/goldarchive_line',
    component: () => import('./components/GoldArchiveLineComponent.vue')
  },
  // {
  //   path: '/services',
  //   component: () => import('./components/ServicesComponent.vue')
  // }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router