import { createApp } from "vue";
//import VueRouter from 'vue-router'
import App from './DefaultComponent.vue'

import router from './routes.js'
import '@fortawesome/fontawesome-free/js/all.js'
const app = createApp(App);
app.use(router);
app.mount("#app");

let prevScrollPosition = 0;
document.body.onscroll = function () {

//   document.getElementById('logoPresentation').style.opacity = 1 - window.scrollY / 2000;

  if (prevScrollPosition < 120 && window.scrollY > 120) {
    document.getElementById('header-top-line').classList.toggle("hide");
    // document.getElementById('mouse').style.display="none";
    
    prevScrollPosition = window.scrollY;
    console.log('scroll');

  }
  if (prevScrollPosition > 120 && window.scrollY < 120) {
    document.getElementById('header-top-line').classList.toggle("hide");
    // document.getElementById('mouse').style.display="block";
    prevScrollPosition = window.scrollY;
    console.log('scroll');
  }

  console.log(prevScrollPosition);

}


//s.style.color = "#ff1111";
