<template>
  <header class="navWrap">
    <nav>
      <div class="logo">
        <a href="/">
        <img src="/images/styles/nav-logo.svg" alt="" />
        </a>
      </div>
      <div class="right-side">
        <div class="top-line" id="header-top-line">
          <p>Официальный сайт торговой марки Recassist</p>
          <p>Москва ул. Енисейская д1С7</p>
        </div>
        <div class="menu-line">
          <ul class="menu">
            <li>
              <router-link to="/">Главная</router-link>
            </li>
            <!-- <li>
              <router-link to="/advantages">Преимущества Recassist</router-link>
            </li> -->
            <li>
              <!-- <router-link to="/whatabout">Вопрос-ответ</router-link> -->
              <a href="/#faq">Вопрос-ответ</a>
            </li>
            <li>
              <!-- <router-link to="/stocks">Где купить</router-link> -->
              <a href="/#sellers">Где купить</a>
            </li>
            <li>
              <!-- <router-link to="/stocks">Где купить</router-link> -->
              <a href="/#ClientsService">Клиентский сервис</a>
            </li>

            <!-- <li>
              <router-link to="/services">Клиентский сервис </router-link>
            </li> -->
          </ul>
          <div class="contacts">
            <a class="phone" href="tel://88005008971">
              <span class="icon"> <i class="fas fa-phone"></i></span>
              <span class="phone-number">8-800-500-8971</span>
            </a>
            <a class="email" href="mailto:info@cdprint.su">
              <span class="icon"> <i class="fas fa-at"></i></span>
              <span class="email-address">info@cd-print.su</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <router-view />
  
  <Footer />  
</template>

<script>
import Footer from './components/Footer.vue'

export default {
  name: "DefaultComponent",
  data() {
    return {
      rrer: "",
    };
  },
  components: {
    Footer
  },
  methods: {
    iclick() {
      this.rrer = "";
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
#app {
  margin-top: 80px;
}
* {
  margin: 0;
  padding: 0;
}
.navWrap {
  background-color: #000;
  -webkit-box-shadow: 0px 10px 20px -10px rgba(255, 255, 255, 0.42);
  -moz-box-shadow: 0px 10px 20px -10px rgba(255, 255, 255, 0.42);
  box-shadow: 0px 10px 20px -10px rgba(255, 255, 255, 0.42);
  position: fixed;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;

  a {
    color: white;
    text-decoration: none;
  }
  ul,
  li,
  p {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

nav {
  display: flex;
  margin: 0 auto;
  max-width: 1980px;
  // width: 100%;
  padding: 12px;
  color: white;

  .logo {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .right-side {
    flex-grow: 1;
    .top-line {
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid #505050;
      padding: 12px;
      transition: all 0.5s;
      p {
        margin-left: 20px;
        color: #8b8b8b;
      }
      &.hide {
        overflow-y: hidden;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: unset;
      }
    }
  }
  .menu-line {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    .menu {
      display: flex;
      // justify-content: center;
      flex-grow: 1;
      li {
        margin-left: 15px;
        a.router-link-active {
          border-bottom: 1px dashed #f00;
        }
      }
    }
    .contacts {
      a {
        margin-left: 20px;
      }
      & > * {
        padding: 6px 12px;
        transition: all 1s;
        &:hover {
          background-color: rgba(255, 255, 255, 0.6);
        }
      }
      .phone {
        .icon {
          font-size: 20px;
        }
        .phone-number {
          margin-left: 12px;
        }
      }
      .email {
        .icon {
          font-size: 20px;
        }
        .email-address {
          margin-left: 12px;
        }
      }
    }
  }
}

section {
  // border-top: #494949 10px solid;
  h1 {
    max-width: 1300px;
    margin: 30px auto;
    color: white;
  }
}
main {
  padding: 6px 12px;
  margin-top: 110px;
  min-height: calc(100vh - 322px);
}
footer {
  min-height: 200px;
  // border-top: #494949 10px solid;
}

body {
  background-color: #000;
  font-family: "Raleway", sans-serif;
}
</style>
