<template>
  <footer>
    <div class="footer-container">
      <div class="logo-wrap">
        <div class="logo">
          <img src="/images/styles/nav-logo.svg" alt="" />
        </div>
        <span>TM Registered 2021</span>
      </div>
      <div class="menu-wrap">
        <h3>Линейка продукции</h3>
        <ul>
          <li>
            <a href="/medicine_line">MEDICINE Line</a>
          </li>
          <li>
            <a href="/profi_line">PROFI Line</a>
          </li>
          <li>
            <a href="/goldarchive_line">GOLD ARCHIVE Line</a>
          </li>
        </ul>
      </div>
      <div class="sellers-wrap">        
          <h3>Где купить</h3>
          <ul>
            <li>
              <a href="http://opt-disc.ru" target="_blanc">Опт-диск</a>
            </li>
            <li>
              <a href="http://cdprint.su" target="_blanc">Типография CD-Print</a>
            </li>
            <li>
              <a href="http://mrt-disc.ru" target="_blanc">МРТ-Диск</a>
            </li>
          </ul>
       
      </div>
      <div class="contacts-wrap">
        
          <h3>Контакты</h3>
          <ul>
            <li>
             <a class="phone" href="tel://88005008971" target="_blanc">
              <span class="icon"> <i class="fas fa-phone"></i></span>
              <span class="phone-number">8-800-500-8971</span>
            </a>
            </li>
            <li>
             <a class="email" href="mailto:info@cdprint.su" target="_blanc">
              <span class="icon"> <i class="fas fa-at"></i></span>
              <span class="email-address">info@cd-print.su</span>
            </a>
            </li>
            <li>
             <a class="address" href="https://yandex.ru/maps/-/CCUBbRGzdD" target="_blanc">
              <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
              <span class="address">Москва ул. Енисейская д1С7</span>
            </a>
            </li>
            
          </ul>
       
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "WhataboutComponent",
  data() {
    return {
      rrer: "",
    };
  },
  // components: {
  //   ByeWorld
  // },
  methods: {
    iclick() {
      this.rrer = "";
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  padding: 30px;
  .contacts-wrap {
    .sellers {
      a {
        color: white;
        text-decoration: none;
        &:hover {
          color: red;
        }
      }
    }
  }
  .footer-container {
    max-width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    & > div {
      margin: 0 20px;
      width: 33%;
    }

    h3 {
      color: white;
      font-size: 20px;
      font-weight: 400px;
    }

    .logo-wrap {
      text-align: left;
      span {
        color: rgb(110, 109, 109);
        margin-left: 120px;
      }
    }

    .menu-wrap,
    .contacts-wrap,
    .sellers-wrap {
      color: white;
      h3 {
        display: inline-block;
        font-size: 25px;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        color: #eeeeee52;
      }
      ul {
        list-style: none;
        margin-left: 10px;
        li {
          margin-top: 12px;
          a {
            color: white;
            text-decoration: none;
            padding-bottom: 8px;
            &:hover {
              // text-decoration: underline;
              color: red;
            }

            span {
              display: inline-block;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
